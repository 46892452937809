import React from "react";
import "./Styles/cardGrid.css";

const TravelCards = () => {
  return (
    <section>
      <div className="card">
        <div className="card-img">
          <img
            src="https://media.tacdn.com/media/attractions-splice-spp-674x446/06/de/9e/95.jpg"
            alt="Big Ben"
          />
          <div className="card-overlay">
            <h2>Abu Dhabi</h2>
            <p>Ferrari World</p>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-img">
          <img
            src="https://www.worldatlas.com/r/w1200/upload/b8/08/7a/shutterstock-1196821240.jpg"
            alt="Eiffel Tower"
          />
          <div className="card-overlay">
            <h2>Dubai</h2>
            <p>Burj Khalifa</p>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-img">
          <img
            src="https://media.cntraveler.com/photos/5c6c4a49e243ea4a765e8419/16:9/w_2560,c_limit/Dune-Bashing.jpg"
            alt="Colosseum"
          />
          <div className="card-overlay">
            <h2>Dubai</h2>
            <p>Desert Safari</p>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-img">
          <img
            src="https://images.unsplash.com/photo-1581473483413-313a5afffb08?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=714&q=80"
            alt="Pisa Tower"
          />
          <div className="card-overlay">
            <h2>Pisa</h2>
            <p>Pisa Tower</p>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-img">
          <img
            src="https://images.unsplash.com/photo-1585155967849-91c736589c84?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=627&q=80"
            alt="Statue of Liberty"
          />
          <div className="card-overlay">
            <h2>New York</h2>
            <p>Statue of Liberty</p>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-img">
          <img
            src="https://images.unsplash.com/photo-1527915676329-fd5ec8a12d4b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80"
            alt="Sydney Opera House"
          />
          <div className="card-overlay">
            <h2>Sydney</h2>
            <p>Sydney Opera House</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TravelCards;
