import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Left, Right } from "./Arrows";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Styles/top-cities.css";

const CityCard = ({ city }) => (
  <div className="city-card">
    <img src={city.url} alt={city.city} />
    <p className="content">{city.city}</p>
  </div>
);
class TopCities extends Component {
  render() {
    return (
      <div>
        <div className="top-cities-wrapper">
          <h1>Top Cities on Headout</h1>
          <hr
            style={{
              backgroundColor: "#ffbb58",
              width: "75px",
              height: "2px",
              border: "none",
              marginTop: "0px",
              marginLeft: "0px",
              marginBottom: "20px",
            }}
          />
          <div className="carousel-wrapper ">
            {" "}
            {topCitiesData.map((city) => (
              <CityCard key={city.id} city={city} />
            ))}
          </div>
          <hr
            style={{
              height: "1px",
              color: "#e7e7e7",
              borderTop: "none",
              borderLeft: "none",
            }}
          />
        </div>
        <hr className="section-divide-hr" />
      </div>
    );
  }
}

const topCitiesData = [
  {
    id: 1,
    city: "Abu Dhabi",
    route: "new-york",
    description: "Take a bite of the Big Apple",
    url:
      "https://cdn-images-rayna.imgix.net/imgiximages/city-images/13236/abu-dhabi.jpg",
  },
  {
    id: 2,
    city: "Ras al Khaimah",
    route: "ras-al-khaimah",
    description: "An offer you can't refuse",
    url:
      "https://cdn-images-rayna.imgix.net/imgiximages/city-images/14644/ras-al-khaimah-city.png",
  },
  {
    id: 3,
    city: "Dubai",
    route: "dubai",
    description: "Roam the eternal city",
    url:
      "https://www.travelandleisure.com/thmb/k53-FUyx7Uyg1CzpuTuMSZXvY64=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/TAL-dubai-DUBAITG1123-17390625954c4be3902a440d8fffde67.jpg",
  },
  {
    id: 4,
    city: "X Dubai",
    route: "paris",
    description: "C'est La Vie",
    url:
      "https://dubaitravelblog.com/wp-content/uploads/2018/08/wingsuits-dubai-marina-xdubai.jpg",
  },
  {
    id: 5,
    city: "Moon Resort",
    route: "london",
    description: "For everything hunky-dory",
    url:
      "https://media.architecturaldigest.com/photos/631f7f63f9b23c63c2313f54/4:3/w_1896,h_1422,c_limit/Dubai%20Pearl%207_Final%20(002).jpg",
  },
  {
    id: 6,
    city: "Dubai",
    route: "dubai",
    description: "An Oasis like no other",
    url:
      "https://cdn-imgix.headout.com/cities/dubai/images/mobile/morning.jpg?auto=compress&fm=webp&w=412.5&h=486&crop=faces&fit=min",
  },
  {
    id: 7,
    city: "Barcelona",
    route: "barcelona",
    description: "Hacer Peunte a Catalunya",
    url:
      "https://cdn-imgix.headout.com/cities/barcelona/images/mobile/morning.jpg?auto=compress&fm=webp&w=412.5&h=486&crop=faces&fit=min",
  },
  {
    id: 8,
    city: "Madrid",
    route: "madrid",
    description: "Discover the hear of Spain",
    url:
      "https://cdn-imgix.headout.com/cities/madrid/images/mobile/morning.jpg?auto=compress&fm=webp&w=412.5&h=486&crop=faces&fit=min",
  },
  {
    id: 9,
    city: "Singapore",
    route: "singapore",
    description: "The Lion City",
    url:
      "https://cdn-imgix.headout.com/cities/singapore/images/mobile/morning.jpg?auto=compress&fm=webp&w=412.5&h=486&crop=faces&fit=min",
  },
  {
    id: 10,
    city: "Venice",
    route: "venice",
    description: "Enjoy and have fun in the City",
    url:
      "https://cdn-imgix.headout.com/cities/venice/images/mobile/morning.jpg?auto=compress&fm=webp&w=412.5&h=486&crop=faces&fit=min",
  },
  {
    id: 11,
    city: "Milan",
    route: "milan",
    description: "Enjoy and have fun in the City",
    url:
      "https://cdn-imgix.headout.com/cities/milan/images/mobile/morning.jpg?auto=compress&fm=webp&w=412.5&h=486&crop=faces&fit=min",
  },
  {
    id: 12,
    city: "Naples",
    route: "naples",
    description: "Enjoy and have fun in the City",
    url:
      "https://cdn-imgix.headout.com/cities/naples/images/mobile/morning.jpg?auto=compress&fm=webp&w=412.5&h=486&crop=faces&fit=min",
  },
  {
    id: 13,
    city: "Budapest",
    route: "budapest",
    description: "Enjoy and have fun in the City",
    url:
      "https://cdn-imgix.headout.com/cities/budapest/images/mobile/morning.jpg?auto=compress&fm=webp&w=412.5&h=486&crop=faces&fit=min",
  },
  {
    id: 14,
    city: "Edinburg",
    route: "edinburg",
    description: "Enjoy and have fun in the City",
    url:
      "https://cdn-imgix.headout.com/cities/edinburgh/images/mobile/morning.jpg?auto=compress&fm=webp&w=412.5&h=486&crop=faces&fit=min",
  },
  {
    id: 15,
    city: "Florence",
    route: "florence",
    description: "Enjoy and have fun in the City",
    url:
      "https://cdn-imgix.headout.com/cities/florence/images/mobile/morning.jpg?auto=compress&fm=webp&w=412.5&h=486&crop=faces&fit=min",
  },
];

export default TopCities;
