// src/TravelMenu.js

import React from "react";
import {
  FaPlane,
  FaHotel,
  FaHome,
  FaUmbrellaBeach,
  FaTrain,
  FaBus,
  FaTaxi,
  FaMoneyBillWave,
  FaShieldAlt,
} from "react-icons/fa";
import "./style.scss";

const TravelMenu = () => {
  const menuItems = [
    { icon: <FaPlane />, label: "Flights" },
    { icon: <FaHotel />, label: "Hotels" },
    { icon: <FaHome />, label: "Homestays & Villas" },
    { icon: <FaUmbrellaBeach />, label: "Holiday Packages" },
    { icon: <FaTrain />, label: "Trains" },
    { icon: <FaBus />, label: "Buses" },
    { icon: <FaTaxi />, label: "Cabs" },
    { icon: <FaMoneyBillWave />, label: "Forex Card & Currency" },
    { icon: <FaShieldAlt />, label: "Travel Insurance" },
  ];

  return (
    <div className="travel-menu">
      {menuItems.map((item, index) => (
        <div key={index} className="menu-item">
          {item.icon}
          <span>{item.label}</span>
        </div>
      ))}
    </div>
  );
};

export default TravelMenu;
